import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import type { IBreadcrumbTitles } from 'components/Breadcrumbs';
import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import type { ISearchOverlay } from 'components/HeaderNavigation/components/MobileNavigation';
import getSearchClient from 'server/repository/algolia/client';
import type { DictionaryItem } from 'service/dictionary/dictionaryService';
import { TrackingService } from 'service/tracking/trackingService';
import TemplateMapper from 'templates/TemplateMapper';
import type { IGlobalMetaData } from 'types/GlobalMetadata';

const Breadcrumbs = dynamic(
  () => import('components/Breadcrumbs').then((m: any) => m.Breadcrumbs),
  { ssr: true },
) as any;
const UpArrow = dynamic(() => import('components/UpArrow').then((m: any) => m.UpArrow), {
  ssr: true,
}) as any;

const ClientSlugPage = ({
  breadcrumbsCMSData,
  data,
  globalMetadata,
  searchOverlayData,
  difficultyCopy,
  recipeCopy,
  searchItemLabels,
}: {
  breadcrumbsCMSData: IBreadcrumbTitles;
  data: any;
  globalMetadata: IGlobalMetaData;
  searchOverlayData: ISearchOverlay;
  recipeCopy: DictionaryItem[];
  difficultyCopy: Record<string, string>;
  searchItemLabels: Record<string, string>;
}) => {
  const page = data.genericPages?.[0] || {};
  const [searchOverlayDataExtended, setSearchOverlayDataExtended] = useState(searchOverlayData);
  const { theme } = useGlobalContext();
  const router = useRouter();

  useEffect(() => {
    const cg1 = page?.contentGroupingTrackingValue;
    const cg2 = page?.contentGroupingTrackingValues;
    if (cg1) {
      if (cg2) {
        TrackingService.contentGroups({
          cg1: cg1.replace(/_/g, ' '),
          cg2: cg2.replace(/_/g, ' '),
        });
      } else {
        TrackingService.contentGroups({
          cg1: cg1.replace(/_/g, ' '),
        });
      }
    }
  }, []);

  useEffect(() => {
    if (!searchOverlayDataExtended.searchClient) {
      const algoliaClient = getSearchClient();
      setSearchOverlayDataExtended({
        ...searchOverlayDataExtended,
        searchClient: algoliaClient,
      });
    }
  }, [searchOverlayData]);

  // Handle route change and script injection
  useEffect(() => {
    const scriptId = 'suppress-cookie-banner';

    const updateCookieBannerScript = (shouldSuppress: boolean) => {
      let script = document.getElementById(scriptId) as HTMLScriptElement;

      if (!script) {
        script = document.createElement('script');
        script.id = scriptId;
        script.type = 'application/javascript';
        document.body.appendChild(script);
      }

      // Update script content with the cookieBanner value
      script.textContent = `window.UC_UI_SUPPRESS_CMP_DISPLAY = ${shouldSuppress};`;
    };

    const handleRouteChange = () => {
      // Set the script to false on route change
      updateCookieBannerScript(false);
    };

    // Set initial script value based on the inverted page configuration
    updateCookieBannerScript(!page?.cookieBannerRequired);

    // Attach route change handler
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      // Trigger a full page reload on component unmount
      if (!page?.cookieBannerRequired) {
        window.location.reload();
      }
      // Cleanup route change handler
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events, page?.cookieBannerRequired]);

  return (
    <>
      {!theme && (
        <Breadcrumbs isPositionRelative titles={breadcrumbsCMSData} pageName={page.title} />
      )}
      <TemplateMapper
        components={page?.pageComponents}
        copy={page?.dictionary?.dictionaryItems || null}
        recipeCopy={recipeCopy}
        difficultyCopy={difficultyCopy}
        globalMetadata={globalMetadata}
        searchItemLabels={searchItemLabels}
        searchData={searchOverlayDataExtended}
      />
      <UpArrow />
    </>
  );
};

export default ClientSlugPage;
